import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Button, Paper, Theme } from '@mui/material'

import { navigateSafely } from '../../../utils/safe-navigation'
import { isAAS } from '../../../utils/web-site'
import { AvailabilityCard } from './availability-card'
import { IQuotes } from '../../../../domain/usecases/quotes'

const useStyles = makeStyles()((theme: Theme) => ({
  banner: {
    background: isAAS() ? `url(/aas/img/top.jpg) no-repeat center` : `url(/aek/img/top.jpg) center`,
    position: 'relative',
  },
  titleContener: {
    textAlign: 'center',
    width: '100%',
    paddingTop: 90,
    paddingBottom: 90,
  },
  title: {
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main,
    fontFamily: 'Candal, sans-serif',
    fontSize: '32px',
    margin: 0,
  },
  subtitle: {
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main,
    fontFamily: 'Candal, sans-serif',
    fontSize: '20px',
    marginTop: 16,
    marginBottom: 0
  },
  sectionDesktop: {
    display: 'none',
    flexGrow: 1,
    width: '70%',
    left: '15%',
    marginTop: -32,
    position: 'absolute',
    padding: '16px 32px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'block',
    flexGrow: 1,
    width: '70%',
    left: '15%',
    marginTop: -32,
    position: 'absolute',
    padding: '16px 16px 16px 32px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export type HeaderSectionProps = {
  id?: string
  quotes: IQuotes
}

export const HeaderSection = ({ id, quotes }: HeaderSectionProps) => {
  const { classes } = useStyles()

  function onReservationButtonClicked() {
    navigateSafely('/app', {
      state: {
        deposit: null,
        pickup: null,
      },
    })
  }

  return (
    <div id={id}>
      <div className={classes.banner}>
        <div className={classes.titleContener}>
          <h1 className={classes.title}>
            Le seul parking privé avec ACCÈS DIRECT
            <br />
            à l’Aéroport de Nantes
          </h1>
          <h4 className={classes.subtitle}>
            Pas de frais caché, Pas de déplacement sur route, voyagez sereinement !
          </h4>
        </div>
      </div>
      <Paper elevation={5} className={classes.sectionDesktop}>
        <AvailabilityCard quotes={quotes} />
      </Paper>
      <div className={classes.sectionMobile}>
        <Button variant="contained" color="secondary" fullWidth onClick={onReservationButtonClicked}>
          RÉSERVER MAINTENANT
        </Button>
      </div>
    </div>
  )
}
